$(document).ready(function() {

  // device menu
  $('#toggle').click(function() {
    $(this).toggleClass('active');
    $('#overlay').toggleClass('open');
  });

  // locale switch
  $('#lang-toggle').click(function() {
    $(this).toggleClass('active');
    console.log('clicked');
    $('#lang-overlay').toggleClass('open');
  });
  $('.lang-switch__option').click(function (event) {
    event.stopPropagation();
    $('#lang-overlay').toggleClass('open');
  });
  $('.lang-switch__close').click(function (event) {
    event.stopPropagation();
    $('#lang-overlay').toggleClass('open');
  });

  // search bar-form
  $('#search-bar-open').click(function() {
    $('#search-bar').toggleClass('open');
  });
  $('#search-bar-close').click(function() {
    $('#search-bar').toggleClass('open');
  });

  // video popup
  $('.popup-video').magnificPopup({
    // disableOn: 700, <- disabling stops devices skipping over to youtube.com
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false
  });


  // initialise slider (1x image + 1x related captions)
  $('.carousel-1').slick({
    autoplay: true,
    dots: true,
    arrows: false,
    dotsClass: 'carousel__dots',
    accessibility: false,
    asNavFor: '.carousel-1__captions'
  });
  $('.carousel-1__captions').slick({
    fade: true,
    dots: false,
    arrows: false,
    accessibility: false
  });

  // initialise feature carousel
  $('.feature-carousel').slick({
    fade: true,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 750,
    dots: false,
    arrows: false,
    accessibility: false
  });

});
